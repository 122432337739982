import React, { useContext, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import { Box, Card, Divider, Icon, SimpleGrid, Text } from '@chakra-ui/react';
import { MdAddCircleOutline } from 'react-icons/md';

const Admin = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [currentTab, setCurrentTab] = useState('details');
  const { currentUser } = useContext(AuthContext);

  if (!['admin'].includes(currentUser.role)) {
    toast({
      title: 'Admins Only!',
      status: 'warning',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
      containerStyle: {
        mt: '74px',
      },
    });
    navigate('/cards');
  }

  if (currentUser.displayName === '')
    return;

  if (currentUser.role !== 'admin')
    return;

  return (
    <>
      <Text fontSize={'4xl'}>Card Management</Text>
      <Divider mt={2} mb={8} />
      <SimpleGrid columns={[1, 2, 3, 6]} spacing={2}>
        <Card
          p={8}
          onClick={() => navigate('/admin/addCard')}
          _hover={{
            bg: 'brand.100',
            cursor: 'pointer',
          }}
        >
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Icon fontSize={'6xl'} as={MdAddCircleOutline} />
          </Box>
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Text fontSize={'2xl'}>Add Card</Text>
          </Box>
        </Card>
      </SimpleGrid>
    </>
  );
};

export default Admin;
