import { extendTheme, useColorMode } from '@chakra-ui/react';
import { ButtonTheme } from './components';

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

const config = {
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    brand: {
      50: '#FAFAFA',
      100: '#F5F5F5',
      200: '#EEEEEE',
      300: '#E0E0E0',
      400: '#BDBDBD',
      500: '#9E9E9E',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
    },
    accent: {
      50: '#fce8e5',
      100: '#ffcbb7',
      200: '#ffa889',
      300: '#ff8659',
      400: '#ff6b33',
      500: '#ff5100',
      600: '#f44b00',
      700: '#e64300',
      800: '#d93c00',
      900: '#c12c00',
    },
  },
  components: {
    Button: ButtonTheme,
    Form: {
      variants: {
        floating: ({ colorMode }) => ({
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: colorMode === 'light' ? 'white' : '#2D3748',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
            },
          },
        }),
      },
    },
  },
  styles: {
    global: (props) => ({
      '*::-webkit-scrollbar': {
        width: '0.4em !important',
        height: '0.4em !important',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00) !important',
        bg: 'brand.900',
      },
      '*::-webkit-scrollbar-thumb:vertical': {
        bg: props.colorMode === 'dark' ? 'accent.200' : 'accent.400',
        outline: '1px solid slategrey !important',
      },
      '*::-webkit-scrollbar-thumb:horizontal': {
        bg: props.colorMode === 'dark' ? 'accent.200' : 'accent.400',
        outline: '1px solid slategrey !important',
      },
    }),
  },
};

const theme = extendTheme({ ...config });

export default theme;
