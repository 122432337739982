import React, { useContext } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Input,
  Text,
  Flex,
  Box,
  Spacer,
  VStack,
  StackDivider,
} from '@chakra-ui/react';
import { AuthContext } from '../../../../../../contexts/AuthContext';
import {
  CardInformation,
  CardType,
  Difficulty,
  Format,
  Keyword,
  Symbol,
  Attack,
  Block,
} from './components';

const FilterPanel = ({ isOpen, onClose, btnRef }) => {
  const { dispatch } = useContext(AuthContext);

  const handleApplyFilters = () => {
    dispatch({
      type: 'CARD_FILTER',
      data: {},
    });
    onClose();
  };

  const handleResetFilters = () => {
    dispatch({
      type: 'CARD_FILTER_RESET',
      data: {},
    });
    onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      size={'lg'}
    >
      <DrawerContent>
        <DrawerCloseButton />

        <DrawerBody>
          <VStack
            divider={<StackDivider borderColor="gray.200" />}
            spacing={3}
            pt={4}
            align="stretch"
          >
            <Box>
              <CardType />
            </Box>
            <Box>
              <Symbol />
            </Box>
            <Box>
              <Keyword />
            </Box>
            <Box>
              <Block />
            </Box>
            <Box>
              <Attack />
            </Box>
            <Box>
              <CardInformation />
            </Box>
            {/*<Box>*/}
            {/*  <Format />*/}
            {/*</Box>*/}
          </VStack>
        </DrawerBody>

        <DrawerFooter>
          <Flex width="100%">
            <Box>
              <Button
                onClick={() => handleResetFilters()}
                variant={'outline'}
                colorScheme={'red'}
              >
                Clear Filters
              </Button>
            </Box>
            <Spacer />
            <Box>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
            </Box>
            <Box>
              <Button colorScheme="accent" onClick={() => handleApplyFilters()}>
                Apply Filters
              </Button>
            </Box>
          </Flex>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default FilterPanel;
