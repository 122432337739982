import React, { useContext } from 'react';
import {
  Box,
  Button,
  Card,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useColorMode,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { AuthContext } from '../../contexts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import NavItem from './NavItem';
import { RxCopy, RxCube, RxLayers } from 'react-icons/rx';
import { Login } from '../Authentication/components';
import { RxEnter, RxExit } from 'react-icons/rx';
import { Authentication } from '../index';

const MobileNavBar = () => {
  const location = useLocation();
  const { currentUser, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { toggleColorMode, colorMode } = useColorMode();
  return (
    <Card
      position={'fixed'}
      bottom={'0px'}
      left={'0px'}
      width={'100vw'}
      zIndex={25}
      height={'80px'}
      bg={'brand.900'}
    >
      <SimpleGrid columns={4} spacing={0}>
        <Box bg="brand.900" height="80px">
          <NavItem
            active={location.pathname.includes('cards')}
            icon={RxCopy}
            text={'Cards'}
            link={'/cards'}
            width={'100%'}
          />
        </Box>
        <Box bg="brand.900" height="80px">
          <NavItem
            active={location.pathname.includes('decks')}
            icon={RxLayers}
            text={'Decks'}
            link={'/decks'}
            width={'100%'}
          />
        </Box>
        <Box bg="brand.900" height="80px">
          <NavItem
            active={location.pathname.includes('collection')}
            icon={RxCube}
            text={'Collection'}
            link={'/collection'}
            width={'100%'}
          />
        </Box>
        {Object.keys(currentUser).length > 0 ? (
          <Box bg="brand.900" height="80px">
            <Menu width={'100%'}>
              <MenuButton
                as={Button}
                bg={'brand.900'}
                borderRadius={'none'}
                w={'100%'}
                h={'80px'}
                m={'0 auto'}
                leftIcon={
                  <Image
                    src={currentUser.photoURL || '/defaultUser.png'}
                    h={'50px'}
                    borderRadius={'full'}
                    ml={2}
                  />
                }
              />
              <MenuList>
                {['admin'].includes(currentUser.role) && (
                  <MenuItem onClick={() => navigate('/admin')}>
                    Admin Tools
                  </MenuItem>
                )}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        ) : (
          <Box
            display={'flex'}
            h="80px"
            w={['100%', '100%', '80px']}
            alignItems={'center'}
            justifyContent={'center'}
            _hover={{
              bg: 'brand.800',
              cursor: 'pointer',
            }}
            onClick={onOpen}
          >
            <VStack>
              <Icon
                as={RxEnter}
                boxSize={['1.5em', '1.5em', '2em']}
                color={'white'}
              />
              <Text color={'white'} fontSize={['sm', 'sm', 'md']}>
                Login
              </Text>
            </VStack>
          </Box>
        )}
      </SimpleGrid>
      <>{isOpen && <Authentication isOpen={isOpen} onClose={onClose} />}</>
    </Card>
  );
};

export default MobileNavBar;
