import React, { useContext, useRef } from 'react';
import {
  Card,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { MdSearch, MdFilterList } from 'react-icons/md';
import { AuthContext } from '../../../../contexts/AuthContext';
import { FilterPanel } from './components';

const DesktopSearchbar = () => {
  const { dispatch, cardCount } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const handleSearch = (e) => {
    dispatch({
      type: 'CARD_SEARCH',
      data: {
        name: e.target.value,
      },
    });
  };

  return (
    <>
      <Card ml={24} mr={24} h={'40px'} bgColor={'chakra-body-bg'}>
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            fontSize="1.2em"
            children={<MdSearch />}
          />
          <Input
            placeholder="Search card name or text..."
            onChange={(e) => handleSearch(e)}
            type={'text'}
          />
          <InputRightElement
            color="gray.300"
            fontSize="1.2em"
            width={'225px'}
            children={
              <>
                <Button colorScheme={'green'} width={'175px'} variant="outline">
                  Cards found: {cardCount}
                </Button>
                <Button
                  width={'50px'}
                  variant="outline"
                  ref={btnRef}
                  onClick={onOpen}
                >
                  <MdFilterList />
                </Button>
              </>
            }
          />
        </InputGroup>
      </Card>
      {isOpen && (
        <FilterPanel btnRef={btnRef} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

export default DesktopSearchbar;
