import React, { useContext, useEffect, useState } from 'react';
import {
  SimpleGrid,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  useColorMode,
  Spacer,
  InputLeftElement,
  InputGroup,
  Select,
} from '@chakra-ui/react';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { capitalize } from '../../../../../../../../utils/capitalize';

const availableBlockZones = ['high', 'mid', 'low'];

const Block = () => {
  const { dispatch, cardFilter, cardFilterStaging } = useContext(AuthContext);
  const colorMode = useColorMode();
  const [blockZones, setBlockZones] = useState([...cardFilter.blockZones]);
  const [blockModifier, setBlockModifier] = useState(cardFilter.blockModifier);
  const [blockOperator, setBlockOperator] = useState(cardFilter.blockOperator);

  const isSelected = (selectedType) => {
    return blockZones.includes(selectedType) ? 'solid' : 'outline';
  };

  const handleSelect = (selectedZone) => {
    const cardTypeIndex = blockZones.indexOf(selectedZone);

    if (cardTypeIndex > -1) {
      setBlockZones([
        ...blockZones.slice(0, cardTypeIndex),
        ...blockZones.slice(cardTypeIndex + 1),
      ]);
    } else {
      setBlockZones(
        [...blockZones, selectedZone].sort((a, b) => a.localeCompare(b))
      );
    }
  };

  const handleOperator = (event) => {
    setBlockOperator(event.target.value);
  };

  useEffect(() => {
    dispatch({
      type: 'CARD_INFORMATION_FILTER',
      data: {
        blockZones,
        blockModifier,
        blockOperator,
      },
    });
  }, [blockZones, blockModifier, blockOperator]);

  return (
    <div>
      <Text fontSize={'xl'}>Block</Text>
      <SimpleGrid columns={[1]} spacing={2}>
        <SimpleGrid mt={4} columns={[3, 5]} spacing={2}>
          {availableBlockZones.map((bz) => (
            <Button onClick={() => handleSelect(bz)} variant={isSelected(bz)}>
              <Text fontSize="sm">{capitalize(bz)}</Text>
            </Button>
          ))}
        </SimpleGrid>
        <FormControl mt={1}>
          <FormLabel>Block Modifier</FormLabel>
          <InputGroup>
            <InputLeftElement w={'70px'}>
              <Select
                value={blockOperator}
                onChange={(event) => handleOperator(event)}
              >
                <option value="equals">=</option>
                <option value="greater-than">&gt;</option>
                <option value="greater-than-or-equal">&gt;=</option>
                <option value="less-than">&lt;</option>
                <option value="less-than-or-equal">&lt;=</option>
              </Select>
            </InputLeftElement>
            <Input
              focusBorderColor={
                colorMode === 'light' ? 'accent.500' : 'accent.200'
              }
              placeholder=" "
              value={blockModifier}
              onChange={(event) => setBlockModifier(event.target.value)}
              pl={20}
            />
          </InputGroup>
        </FormControl>
      </SimpleGrid>
    </div>
  );
};

export default Block;
