import React from 'react';
import { Text } from '@chakra-ui/react';

const Response = ({ children, mr = 2 }) => {
  return (
    <Text
      mr={mr}
      bgColor="#98bf5e"
      bgClip="text"
      fontWeight="extrabold"
      display="inline"
    >
      {children}
    </Text>
  );
};

export default Response;
