import state from '../contexts/initState';

export const setLocalStorage = async (value) => {
  const currentState = getLocalStorage();
  const newState = {
    ...currentState,
    ...value,
  };
  localStorage.setItem('uvsdecksgg_state', JSON.stringify(newState));
  return await getLocalStorage();
};

export const setLocalStorageNode = async (node, value) => {
  const currentState = await getLocalStorage();
  const newState =
    node !== ''
      ? {
          ...currentState,
          [node]: {
            ...currentState[node],
            ...value,
          },
        }
      : {
          ...currentState,
          ...value,
        };
  localStorage.setItem('uvsdecksgg_state', JSON.stringify(newState));
  return await getLocalStorage();
};

export const getLocalStorage = async () => {
  const data = localStorage.getItem('uvsdecksgg_state');

  if (data === null) localStorage.setItem('uvsdecksgg_state', '{}');

  return JSON.parse(data);
};

export const getLocalStorageNode = async (node) => {
  let data = localStorage.getItem('uvsdecksgg_state');

  if (data === null) localStorage.setItem('uvsdecksgg_state', '{}');

  data = JSON.parse(data);

  if (!data) return '';

  return data[node];
};

export const updateState = async () => {
  localStorage.setItem('uvsdecksgg_state', JSON.stringify(state));
};
