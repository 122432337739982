import React, { useContext, useEffect, useState } from 'react';
import { UvsCard } from '../../components';
import { db } from '../../firebase';
import {
  collection,
  query,
  getDocs,
  setDoc,
  getDoc,
  doc,
} from 'firebase/firestore';
import {
  Box,
  Center,
  SimpleGrid,
  CircularProgress,
  Text,
  Image,
} from '@chakra-ui/react';
import { AuthContext } from '../../contexts/AuthContext';
import {
  getLocalStorageNode,
  setLocalStorageNode,
} from '../../utils/localStorage';
import { v4 as uuid } from 'uuid';
import { capitalize } from '../../utils/capitalize';
import something from './partials.json';
import axios from 'axios';

const cardCollection = collection(db, 'cards');
const appVersion = doc(db, 'appVersion', 'qa5McC3QfevQiEDhHFGI');

const Cards = () => {
  const { cardFilter, dispatch } = useContext(AuthContext);
  const [allCards, setAllCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [visibleCards, setVisibleCards] = useState([]);
  const [cardCount, setCardCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('Loading cards...');

  const fetchCards = async (currentVersion) => {
    setMessage('Getting fresh cards...');
    const foundCards = (await getDocs(query(cardCollection))).docs.map((c) =>
      c.data()
    );
    await setLocalStorageNode('cards', {
      list: [...foundCards],
    });
    await setLocalStorageNode('version', {
      data: currentVersion,
    });

    return foundCards;
  };

  const listCards = async () => {
    setLoading(true);
    let cardList;

    let foundCards = await getLocalStorageNode('cards');
    let version = await getLocalStorageNode('version');
    let currentVersion = await getDoc(appVersion);
    console.log(currentVersion.data().version);

    if (
      foundCards &&
      version !== undefined &&
      version &&
      version?.data === currentVersion.data().version
    )
      cardList = [...foundCards.list];
    else {
      console.log('need fresh cards...');
      cardList = await fetchCards(currentVersion.data().version);
    }

    cardList.sort((a, b) => b.set - a.set || a.cardNumber - b.cardNumber);

    // Set data
    setAllCards([...cardList]);
    setLoading(false);
  };

  const handleLoadMore = () => {
    setVisibleCards([
      ...filteredCards.slice(0, visibleCards.length + pageSize),
    ]);
  };

  const handleFilter = () => {
    let cardList = [...allCards];

    if (cardFilter.format !== 'all')
      cardList = [
        ...cardList.filter((c) => {
          return c.formats.includes(cardFilter.format);
        }),
      ];

    if (cardFilter.type.length > 0) {
      const typeFilters = cardFilter.type.map((t) => t);

      cardList = [
        ...cardList.filter((card) => typeFilters.includes(card.type)),
      ];
    }

    if (cardFilter.symbols.items.length > 0) {
      const symbolFilters = cardFilter.symbols.items.map((s) =>
        s.toLowerCase()
      );

      if (cardFilter.symbols.matchAll)
        cardList = [
          ...cardList.filter((card) =>
            symbolFilters.every((wantedSymbols) =>
              card.symbols
                .map((cs) => cs.toLowerCase())
                .some((foundSymbols) => foundSymbols.includes(wantedSymbols))
            )
          ),
        ];
      else
        cardList = [
          ...cardList.filter(
            (card) =>
              symbolFilters.some((wantedSymbols) =>
                card.symbols
                  .map((cs) => cs.toLowerCase())
                  .some((foundSymbols) => foundSymbols.includes(wantedSymbols))
              ) ||
              card.symbols.map((cs) => cs.toLowerCase()).includes('infinity')
          ),
        ];
    }

    if (cardFilter.difficulty !== '') {
      console.log(`Difficulty ${cardFilter.difficulty}`);
      cardList = [
        ...cardList.filter((card) => {
          if (cardFilter.difficultyOperator === 'greater-than')
            return card.difficulty > Number(cardFilter.difficulty);
          else if (cardFilter.difficultyOperator === 'greater-than-or-equal')
            return card.difficulty >= Number(cardFilter.difficulty);
          else if (cardFilter.difficultyOperator === 'less-than')
            return card.difficulty < Number(cardFilter.difficulty);
          else if (cardFilter.difficultyOperator === 'less-than-or-equal')
            return card.difficulty <= Number(cardFilter.difficulty);
          else return card.difficulty === Number(cardFilter.difficulty);
        }),
      ];
    }

    if (cardFilter.controlCheck !== '') {
      console.log(`Control Check: ${cardFilter.controlCheck}`);
      cardList = [
        ...cardList.filter((card) => {
          if (cardFilter.controlCheckOperator === 'greater-than')
            return card.controlCheck > Number(cardFilter.controlCheck);
          else if (cardFilter.controlCheckOperator === 'greater-than-or-equal')
            return card.controlCheck >= Number(cardFilter.controlCheck);
          else if (cardFilter.controlCheckOperator === 'less-than')
            return card.controlCheck < Number(cardFilter.controlCheck);
          else if (cardFilter.controlCheckOperator === 'less-than-or-equal')
            return card.controlCheck <= Number(cardFilter.controlCheck);
          else return card.controlCheck === Number(cardFilter.controlCheck);
        }),
      ];
    }

    if (cardFilter.health !== '') {
      console.log(`Health: ${cardFilter.health}`);
      cardList = [
        ...cardList.filter((card) => {
          if (cardFilter.healthOperator === 'greater-than')
            return card.health > Number(cardFilter.health);
          else if (cardFilter.healthOperator === 'greater-than-or-equal')
            return card.health >= Number(cardFilter.health);
          else if (cardFilter.healthOperator === 'less-than')
            return card.health < Number(cardFilter.health);
          else if (cardFilter.healthOperator === 'less-than-or-equal')
            return card.health <= Number(cardFilter.health);
          else return card.health === Number(cardFilter.health);
        }),
      ];
    }

    if (cardFilter.handSize !== '') {
      console.log(`Hand Size: ${cardFilter.handSize}`);
      cardList = [
        ...cardList.filter((card) => {
          if (cardFilter.handSizeOperator === 'greater-than')
            return card.handSize > Number(cardFilter.handSize);
          else if (cardFilter.handSizeOperator === 'greater-than-or-equal')
            return card.handSize >= Number(cardFilter.handSize);
          else if (cardFilter.handSizeOperator === 'less-than')
            return card.handSize < Number(cardFilter.handSize);
          else if (cardFilter.handSizeOperator === 'less-than-or-equal')
            return card.handSize <= Number(cardFilter.handSize);
          else return card.handSize === Number(cardFilter.handSize);
        }),
      ];
    }

    if (cardFilter.blockZones?.length > 0) {
      console.log(`Block Zone: ${cardFilter.blockZones}`);
      cardList = [
        ...cardList.filter((card) =>
          cardFilter.blockZones.includes(card.blockZone)
        ),
      ];
    }

    if (cardFilter.blockModifier !== '') {
      console.log(`Block Modifier: ${cardFilter.blockModifier}`);
      cardList = [
        ...cardList.filter((card) => {
          console.log(card.blockModifier === Number(cardFilter.blockModifier));
          if (cardFilter.blockOperator === 'greater-than')
            return card.blockModifier > Number(cardFilter.blockModifier);
          else if (cardFilter.blockOperator === 'greater-than-or-equal')
            return card.blockModifier >= Number(cardFilter.blockModifier);
          else if (cardFilter.blockOperator === 'less-than')
            return card.blockModifier < Number(cardFilter.blockModifier);
          else if (cardFilter.blockOperator === 'less-than-or-equal')
            return card.blockModifier <= Number(cardFilter.blockModifier);
          else return card.blockModifier === Number(cardFilter.blockModifier);
        }),
      ];
    }

    if (cardFilter.attackZones?.length > 0) {
      console.log(`Attack Zone: ${cardFilter.attackZones}`);
      cardList = [
        ...cardList.filter((card) =>
          cardFilter.attackZones.includes(card.attackZone)
        ),
      ];
    }

    if (cardFilter.attackSpeed !== '') {
      console.log(`Attack Speed: ${cardFilter.attackSpeed}`);
      cardList = [
        ...cardList.filter((card) => {
          if (cardFilter.attackSpeedOperator === 'greater-than')
            return card.attackSpeed > Number(cardFilter.attackSpeed);
          else if (cardFilter.attackSpeedOperator === 'greater-than-or-equal')
            return card.attackSpeed >= Number(cardFilter.attackSpeed);
          else if (cardFilter.attackSpeedOperator === 'less-than')
            return card.attackSpeed < Number(cardFilter.attackSpeed);
          else if (cardFilter.attackSpeedOperator === 'less-than-or-equal')
            return card.attackSpeed <= Number(cardFilter.attackSpeed);
          else return card.attackSpeed === Number(cardFilter.attackSpeed);
        }),
      ];
    }

    if (cardFilter.attackDamage !== '') {
      console.log(`Attack Damage: ${cardFilter.attackDamage}`);
      cardList = [
        ...cardList.filter((card) => {
          if (cardFilter.attackDamageOperator === 'greater-than')
            return card.attackDamage > Number(cardFilter.attackDamage);
          else if (cardFilter.attackDamageOperator === 'greater-than-or-equal')
            return card.attackDamage >= Number(cardFilter.attackDamage);
          else if (cardFilter.attackDamageOperator === 'less-than')
            return card.attackDamage < Number(cardFilter.attackDamage);
          else if (cardFilter.attackDamageOperator === 'less-than-or-equal')
            return card.attackDamage <= Number(cardFilter.attackDamage);
          else return card.attackDamage === Number(cardFilter.attackDamage);
        }),
      ];
    }

    if (cardFilter.keywords?.items.length > 0) {
      const keywordFilters = cardFilter.keywords.items.map((kw) =>
        kw.toLowerCase()
      );

      if (cardFilter.keywords?.matchAll)
        cardList = [
          ...cardList.filter((card) =>
            keywordFilters.every((wantedKeywords) =>
              card.keywords
                ?.map((ckw) => ckw.toLowerCase())
                .some((foundKeywords) => foundKeywords.includes(wantedKeywords))
            )
          ),
        ];
      else
        cardList = [
          ...cardList.filter((card) =>
            keywordFilters.some((wantedKeywords) =>
              card.keywords
                ?.map((ckw) => ckw.toLowerCase())
                .some((foundKeywords) => foundKeywords.includes(wantedKeywords))
            )
          ),
        ];
    }

    if (cardFilter.name || cardFilter.name === '') {
      cardList = [
        ...cardList.filter(
          (card) =>
            card.name.toLowerCase().includes(cardFilter.name.toLowerCase()) ||
            card.textSearch
              .toLowerCase()
              .includes(cardFilter.name.toLowerCase())
        ),
      ];
    }

    setFilteredCards([...cardList]);
    setVisibleCards([...cardList.slice(0, pageSize)]);
    dispatch({
      type: 'CARD_COUNT',
      data: {
        cardCount: cardList.length,
      },
    });
  };

  const getData = async () => {
    let foundCards = [];
    const cardsToGet = [82];

    for (let count = 10139; count < 10400; count++) {
      const data = await axios.get(
        `https://universus.cards/card/${count}/__data.json`
      );

      if (data.data?.type === 'redirect') continue;

      const cardData = data.data?.nodes[1]?.data;

      if (
        !cardsToGet.includes(
          cardData[cardData[cardData[cardData[1]?.versions][0]]?.number]
        )
      )
        continue;

      if (cardData) {
        console.log(cardData[cardData[1]?.name]);
        foundCards = [
          ...foundCards,
          {
            id: uuid(),
            image: `https://storage.googleapis.com/uvsdecks.appspot.com/cards/${
              cardData[cardData[cardData[cardData[1]?.versions][0]]?.image]
            }`,
            cardNumber:
              cardData[cardData[cardData[cardData[1]?.versions][0]]?.number] ??
              -1,
            name: cardData[cardData[1]?.name] ?? -1,
            type: cardData[cardData[1]?.type] ?? -1,
            difficulty: cardData[cardData[1]?.difficulty] ?? -1,
            controlCheck: cardData[cardData[1]?.control] ?? -1,
            handSize: cardData[cardData[1]?.handSize] ?? -1,
            health: cardData[cardData[1]?.vitality] ?? -1,
            symbols: cardData[cardData[1]?.symbols].map((symbol) =>
              capitalize(cardData[symbol])
            ),
            blockZone: cardData[cardData[cardData[1]?.block]?.zone] ?? -1,
            blockModifier:
              cardData[cardData[cardData[1]?.block]?.modifier] ?? -1,
            attackZone: cardData[cardData[cardData[1]?.attack]?.zone] ?? -1,
            attackSpeed: cardData[cardData[cardData[1]?.attack]?.speed] ?? -1,
            attackDamage: cardData[cardData[cardData[1]?.attack]?.damage] ?? -1,
            keywords:
              cardData[cardData[1]?.keywords].map((keyword) =>
                capitalize(cardData[keyword])
              ) ?? [],
            abilities:
              cardData[cardData[1]?.abilities].map((ability) =>
                capitalize(cardData[ability])
              ) ?? [],
            textSearch:
              cardData[cardData[1]?.abilities]
                .map((ability) => capitalize(cardData[ability]))
                .join(' ') ?? '',
            rarity:
              cardData[cardData[1]?.type] === 'Character'
                ? 'Character'
                : cardData[
                    cardData[cardData[cardData[1]?.versions][0]]?.rarity
                  ] ?? -1,
            set:
              cardData[cardData[cardData[cardData[1].versions][0]].set]
                ?.number ?? -1,
            setName:
              cardData[cardData[cardData[cardData[1].versions][0]].set]?.name ??
              -1,
            formats: ['retro', 'mha', 'standard'],
            variants: [],
          },
        ];
      }
    }

    console.log(foundCards);
  };

  // useEffect(() => {
  //   getData();
  // }, []);

  const addCard = async (card) => {
    const docRef = doc(db, 'cards', card.id);
    await setDoc(docRef, {
      ...card,
      image: card.image.replace(
        'https://storage.cloud.google.com/uvsdecks.appspot.com/cards/',
        'https://storage.googleapis.com/uvsdecks.appspot.com/cards/'
      ),
    });
  };

  const addCards = async () => {
    // for (const card of something) {
    //   await addCard(card);
    // }
    const cards = await getDocs(collection(db, 'cards'));
    cards.forEach((card) => {
      addCard(card.data());
    });
  };

  // useEffect(() => {
  //   addCards();
  // }, []);

  useEffect(() => {
    listCards();
  }, []);

  useEffect(() => {
    handleFilter();
  }, [cardFilter, allCards]);

  if (loading) {
    return (
      <Center h={['60vh', '100vh', '100vh', '100vh']}>
        <Box w="50%" textAlign="center">
          <CircularProgress isIndeterminate size={[40, 60, 80, 120]} />
          <Text fontSize={['xl', 'xl', '2xl', '3xl']} mt={[4, 8, 8, 8]}>
            {message}
          </Text>
        </Box>
      </Center>
    );
  }

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleScroll = () => {
    const isScrollAtBottom =
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight;

    if (
      isScrollAtBottom &&
      !loading &&
      visibleCards.length <= filteredCards.length
    )
      handleLoadMore();
  };

  window.onscroll = debounce(handleScroll, 250);

  return (
    <Box>
      {visibleCards.length > 0 && (
        <SimpleGrid columns={[2, 2, 3, 5]} spacing={3}>
          {visibleCards.map((card) => (
            <UvsCard card={card} />
          ))}
        </SimpleGrid>
      )}
      {visibleCards.length <= 0 && (
        <>
          <Center>
            <Image
              src={'/images/nothingFound.jpg'}
              w={['50vw', '25vw']}
              borderRadius={24}
            />
          </Center>
          <Center>
            <Text fontSize={'4xl'}>Nothing found...</Text>
          </Center>
        </>
      )}
    </Box>
  );
};

export default Cards;
