import React, { useContext, useState, useEffect, useReducer } from 'react';
import { auth, db } from '../firebase';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  updateProfile,
} from 'firebase/auth';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import initState from './initState';
import reducers from '../reducers';
import { STATE_SAVE, TOAST } from '../actions';
import { useToast } from '@chakra-ui/react';

const userCollection = collection(db, 'users');

export const AuthContext = React.createContext({
  ...initState,
  login: () => {},
  signup: () => {},
  logout: () => {},
  resetPassword: () => {},
  dispatch: () => {},
  setToast: () => {},
  saveState: () => {},
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducers, { ...initState });
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  const setToast = async (data) => {
    await dispatch({
      type: TOAST,
      data,
    });
  };

  async function signup(email, password, displayName) {
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    await updateProfile(auth.currentUser, {
      displayName,
    });
    try {
      await addDoc(userCollection, {
        role: 'user',
        uid: user.uid,
        decks: {},
        collection: {},
      });
    } catch (e) {}
  }

  async function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  const logout = async () => {
    setCurrentUser({});
    await signOut(auth);

    toast({
      title: 'Successfully logged out!',
      status: 'success',
      duration: 3000,
      isClosable: true,
      position: 'top-right',
      containerStyle: {
        mt: '74px',
      },
    });
  };

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  const saveState = async (node, data) => {
    dispatch({ type: STATE_SAVE, node, data });
  };

  const unsubscribe = () => {
    setLoading(true);
    return onAuthStateChanged(auth, async (user) => {
      console.log(loading);
      if (user) {
        const q = query(userCollection, where('uid', '==', `${user.uid}`));
        const { docs } = await getDocs(q);

        setCurrentUser({
          ...user,
          ...docs[0].data(),
        });
      }

      setLoading(false);
    });
  };

  useEffect(() => {
    return unsubscribe();
  }, []);

  const value = {
    ...state,
    currentUser,
    loading,
    login,
    signup,
    logout,
    resetPassword,
    dispatch,
    setToast,
    saveState,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
