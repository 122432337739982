export default {
  version: '0.12', // Update this anytime you make a change to initial initState to update app initState for all users
  fullScreen: false,
  toast: [],
  cardCount: 0,
  cardFilterStaging: {
    symbols: {
      matchAll: false,
      items: [],
    },
    keywords: {
      matchAll: false,
      items: [],
    },
    set: [],
    type: [],
    rarity: [],
    format: 'mha',
    health: '',
    healthOperator: '',
    handSize: '',
    handSizeOperator: '',
    controlCheck: '',
    controlCheckOperator: 'equals',
    difficulty: '',
    difficultyOperator: 'equals',
    blockModifier: '',
    blockZones: [],
    blockOperator: 'equals',
    attackZones: [],
    attackSpeed: '',
    attackDamage: '',
    attackSpeedOperator: 'equals',
    attackDamageOperator: 'equals',
  },
  cards: {
    raw: [],
    filtered: [],
  },
  cardFilter: {
    name: '',
    cardNumber: '',
    symbols: {
      matchAll: false,
      items: [],
    },
    keywords: {
      matchAll: false,
      items: [],
    },
    type: [],
    rarity: [],
    set: [],
    cardText: '',
    format: 'mha',
    health: '',
    healthOperator: '',
    handSize: '',
    handSizeOperator: '',
    controlCheck: '',
    controlCheckOperator: 'equals',
    difficulty: '',
    difficultyOperator: 'equals',
    blockModifier: '',
    blockZones: [],
    blockOperator: 'equals',
    attackZones: [],
    attackSpeed: '',
    attackDamage: '',
    attackSpeedOperator: 'equals',
    attackDamageOperator: 'equals',
  },
};
