import React, { useContext, useEffect, useState } from 'react';
import {
  SimpleGrid,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  InputLeftElement,
  InputGroup,
  useColorMode,
  Select,
} from '@chakra-ui/react';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';
import { capitalize } from '../../../../../../../../utils/capitalize';

const availableAttackZones = ['high', 'mid', 'low'];

const CardInformation = () => {
  const { dispatch, cardFilter, cardFilterStaging } = useContext(AuthContext);
  const colorMode = useColorMode();
  const [attackZones, setAttackZones] = useState([...cardFilter.attackZones]);
  const [attackSpeed, setAttackSpeed] = useState(cardFilter.attackSpeed);
  const [attackSpeedOperator, setAttackSpeedOperator] = useState(
    cardFilter.attackSpeedOperator
  );
  const [attackDamage, setAttackDamage] = useState(cardFilter.attackDamage);
  const [attackDamageOperator, setAttackDamageOperator] = useState(
    cardFilter.attackDamageOperator
  );

  const isSelected = (selectedType) => {
    return attackZones.includes(selectedType) ? 'solid' : 'outline';
  };

  const handleSelect = (selectedZone) => {
    const cardTypeIndex = attackZones.indexOf(selectedZone);

    if (cardTypeIndex > -1) {
      setAttackZones([
        ...attackZones.slice(0, cardTypeIndex),
        ...attackZones.slice(cardTypeIndex + 1),
      ]);
    } else {
      setAttackZones(
        [...attackZones, selectedZone].sort((a, b) => a.localeCompare(b))
      );
    }
  };

  const handleOperator = (event, type) => {
    if (type === 'attackSpeed') setAttackSpeedOperator(event.target.value);
    else if (type === 'attackDamage')
      setAttackDamageOperator(event.target.value);
  };

  const handleChange = (event, item) => {
    if (item === 'attackSpeed') setAttackSpeed(event.target.value);
    else if (item === 'attackDamage') setAttackDamage(event.target.value);
  };

  useEffect(() => {
    dispatch({
      type: 'CARD_INFORMATION_FILTER',
      data: {
        attackZones,
        attackSpeed,
        attackSpeedOperator,
        attackDamage,
        attackDamageOperator,
      },
    });
  }, [
    attackZones,
    attackSpeed,
    attackSpeedOperator,
    attackDamage,
    attackDamageOperator,
  ]);

  return (
    <div>
      <Text fontSize={'xl'}>Attack</Text>
      <SimpleGrid columns={[1]} spacing={2}>
        <SimpleGrid mt={4} columns={[3, 5]} spacing={2}>
          {availableAttackZones.map((az) => (
            <Button onClick={() => handleSelect(az)} variant={isSelected(az)}>
              <Text fontSize="sm">{capitalize(az)}</Text>
            </Button>
          ))}
        </SimpleGrid>
        <SimpleGrid columns={[2]} spacing={2}>
          <FormControl mt={1}>
            <FormLabel>Attack Speed</FormLabel>
            <InputGroup>
              <InputLeftElement w={'70px'}>
                <Select
                  value={attackSpeedOperator}
                  onChange={(event) => handleOperator(event, 'attackSpeed')}
                >
                  <option value="equals">=</option>
                  <option value="greater-than">&gt;</option>
                  <option value="greater-than-or-equal">&gt;=</option>
                  <option value="less-than">&lt;</option>
                  <option value="less-than-or-equal">&lt;=</option>
                </Select>
              </InputLeftElement>
              <Input
                focusBorderColor={
                  colorMode === 'light' ? 'accent.500' : 'accent.200'
                }
                placeholder=" "
                value={attackSpeed}
                onChange={(event) => handleChange(event, 'attackSpeed')}
                pl={20}
              />
            </InputGroup>
          </FormControl>
          <FormControl mt={1}>
            <FormLabel>Attack Damage</FormLabel>
            <InputGroup>
              <InputLeftElement w={'70px'}>
                <Select
                  value={attackDamageOperator}
                  onChange={(event) => handleOperator(event, 'attackDamage')}
                >
                  <option value="equals">=</option>
                  <option value="greater-than">&gt;</option>
                  <option value="greater-than-or-equal">&gt;=</option>
                  <option value="less-than">&lt;</option>
                  <option value="less-than-or-equal">&lt;=</option>
                </Select>
              </InputLeftElement>
              <Input
                focusBorderColor={
                  colorMode === 'light' ? 'accent.500' : 'accent.200'
                }
                placeholder=" "
                value={attackDamage}
                onChange={(event) => handleChange(event, 'attackDamage')}
                pl={20}
              />
            </InputGroup>
          </FormControl>
        </SimpleGrid>
      </SimpleGrid>
    </div>
  );
};

export default CardInformation;
