import React from 'react';
import { Highlight } from '@chakra-ui/react';

const Ability = ({
  type,
  text,
  color,
  characters = '',
  deadlock = false,
  combo = false,
  first = false,
  second = false,
}) => {
  return (
    <Highlight
      query={`${characters !== '' ? characters + ' ' : ''}${
        combo ? 'Combo ' : ''
      }${deadlock ? 'Deadlock ' : ''}${first ? 'First ' : ''}${
        second ? 'Second ' : ''
      }${type}`}
      styles={{ px: '2', py: '1', rounded: 'base', bg: color }}
    >
      {text}
    </Highlight>
  );
};

export default Ability;
