import React, { useContext, useRef } from 'react';
import {
  Button,
  Card,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { MdFilterList, MdSearch } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import { AuthContext } from '../../../../contexts/AuthContext';
import { FilterPanel } from '../DesktopSearchbar/components';

const MobileSearchBar = () => {
  const { dispatch, cardCount } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();

  const handleSearch = (e) => {
    dispatch({
      type: 'CARD_SEARCH',
      data: {
        name: e.target.value,
      },
    });
  };

  return (
    <>
      <Card
        position={'fixed'}
        width={'100vw'}
        zIndex={'30'}
        bgColor={'brand.900'}
        top={'60px'}
        left={'0px'}
        height={'60px'}
        borderRadius={'none'}
      >
        <InputGroup
          height={'50px'}
          w={'96vw'}
          ml={'2vw'}
          bgColor={'chakra-subtle-bg'}
          borderRadius={'md'}
        >
          <InputLeftElement
            pointerEvents="none"
            color="gray.300"
            height={'50px'}
            fontSize="1.2em"
            children={<MdSearch />}
          />
          <Input
            placeholder="Search card name or text..."
            onChange={(e) => handleSearch(e)}
            height={'50px'}
            borderRadius={'md'}
            type={'text'}
          />
          <InputRightElement
            color="gray.300"
            fontSize="1.2em"
            width={'100px'}
            height={'50px'}
            children={
              <>
                <Button
                  colorScheme={'green'}
                  width={'50px'}
                  variant="outline"
                  h={'50px'}
                >
                  {cardCount}
                </Button>
                <Button
                  width={'50px'}
                  variant="outline"
                  ref={btnRef}
                  onClick={onOpen}
                  h={'50px'}
                >
                  <MdFilterList />
                </Button>
              </>
            }
          />
        </InputGroup>
      </Card>
      {isOpen && (
        <FilterPanel btnRef={btnRef} isOpen={isOpen} onClose={onClose} />
      )}
    </>
  );
};

export default MobileSearchBar;
