import React from 'react';
import { Text, Tooltip } from '@chakra-ui/react';
import { Enhance } from '../index';

const Stun = ({ value }) => {
  return (
    <Tooltip
      label="Response: Your rival commits X
foundations. X equals the rating of the Stun
keyword granting this ability."
    >
      <Enhance>{value}</Enhance>
    </Tooltip>
  );
};

export default Stun;
