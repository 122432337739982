import React, { useContext, useEffect, useState } from 'react';
import {
  SimpleGrid,
  Text,
  Button,
  Flex,
  Box,
  Spacer,
  FormControl,
  FormLabel,
  Switch,
  Image,
} from '@chakra-ui/react';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';

const availableSymbols = [
  { value: 'Air', image: '/symbols/Air.png' },
  { value: 'All', image: '/symbols/All.png' },
  { value: 'Chaos', image: '/symbols/Chaos.png' },
  { value: 'Death', image: '/symbols/Death.png' },
  { value: 'Earth', image: '/symbols/Earth.png' },
  { value: 'Evil', image: '/symbols/Evil.png' },
  { value: 'Fire', image: '/symbols/Fire.png' },
  { value: 'Good', image: '/symbols/Good.png' },
  { value: 'Life', image: '/symbols/Life.png' },
  { value: 'Order', image: '/symbols/Order.png' },
  { value: 'Void', image: '/symbols/Void.png' },
  { value: 'Water', image: '/symbols/Water.png' },
  { value: 'Infinity', image: '/symbols/Infinity.png' },
];

const Symbol = () => {
  const { dispatch, cardFilter } = useContext(AuthContext);
  const [symbols, setSymbols] = useState([...cardFilter.symbols.items]);
  const [matchAll, setMatchAll] = useState(cardFilter.symbols.matchAll);

  const isSelected = (selectedSymbol) => {
    return symbols.includes(selectedSymbol.value) ? 'solid' : 'outline';
  };

  const handleToggle = () => {
    setMatchAll(!matchAll);
  };

  const handleSelect = (selectedSymbol) => {
    const cardTypeIndex = symbols.indexOf(selectedSymbol.value);

    if (cardTypeIndex > -1) {
      setSymbols([
        ...symbols.slice(0, cardTypeIndex),
        ...symbols.slice(cardTypeIndex + 1),
      ]);
    } else {
      setSymbols(
        [...symbols, selectedSymbol.value].sort((a, b) => a.localeCompare(b))
      );
    }
  };

  useEffect(() => {
    dispatch({
      type: 'CARD_SYMBOLS_FILTER',
      data: {
        symbols,
        matchAll,
      },
    });
  }, [symbols, matchAll]);

  return (
    <div>
      <Flex>
        <Text fontSize={'xl'}>Symbols</Text>
        <Spacer />
        <Box>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              Match All
            </FormLabel>
            <Switch
              colorScheme="accent"
              isChecked={matchAll}
              onChange={() => handleToggle()}
            />
          </FormControl>
        </Box>
      </Flex>
      <SimpleGrid pt={4} columns={[4, 4, 4, 6]} spacing={2}>
        {availableSymbols.map((symbol) => (
          <Button
            variant={isSelected(symbol)}
            onClick={() => handleSelect(symbol)}
            h={'60px'}
          >
            <Image maxWidth="40px" src={symbol.image} />
          </Button>
        ))}
      </SimpleGrid>
    </div>
  );
};

export default Symbol;
