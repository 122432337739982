import React, { useContext, useEffect } from 'react';
import { Box, ChakraProvider, ColorModeScript, Hide } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import theme from './theme';
import { DesktopNavBar, Header, MobileNavBar } from './components';
import { AuthContext } from './contexts/AuthContext';

const App = () => {
  const { currentUser, loading } = useContext(AuthContext);

  useEffect(() => {
    console.log(theme.config.initialColorMode);
  }, [loading]);

  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        <BrowserRouter basename="/">
          <Box>
            <Box w="100%">
              <Header />
              <Hide below={'lg'}>
                <DesktopNavBar />
              </Hide>
              <Hide above={'md'}>
                <MobileNavBar />
              </Hide>
            </Box>
            <Box
              w="100%"
              pl={[2, 16, 16, 40]}
              pr={[2, 16, 16, 40]}
              pt={[36, 24, 24, 24]}
              mb={[28, 16, 16, 24]}
            >
              <Routes />
            </Box>
          </Box>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
};

export default App;
