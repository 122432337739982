import React, { useContext, useEffect, useState } from 'react';
import {
  SimpleGrid,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  useColorMode,
  InputLeftElement,
  Select,
  InputGroup,
} from '@chakra-ui/react';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';

const availableCardTypes = [
  'Character',
  'Action',
  'Asset',
  'Attack',
  'Foundation',
];
const CardType = () => {
  const { dispatch, cardFilter } = useContext(AuthContext);
  const [cardTypes, setCardsType] = useState([...cardFilter.type]);
  const colorMode = useColorMode();
  const [difficulty, setDifficulty] = useState(cardFilter.difficulty);
  const [difficultyOperator, setDifficultyOperator] = useState(
    cardFilter.difficultyOperator
  );
  const [controlCheck, setControlCheck] = useState(cardFilter.controlCheck);
  const [controlCheckOperator, setControlCheckOperator] = useState(
    cardFilter.controlCheckOperator
  );

  const isSelected = (selectedType) => {
    return cardTypes.includes(selectedType) ? 'solid' : 'outline';
  };

  const handleSelect = (selectedType) => {
    const cardTypeIndex = cardTypes.indexOf(selectedType);

    if (cardTypeIndex > -1) {
      setCardsType([
        ...cardTypes.slice(0, cardTypeIndex),
        ...cardTypes.slice(cardTypeIndex + 1),
      ]);
    } else {
      setCardsType(
        [...cardTypes, selectedType].sort((a, b) => a.localeCompare(b))
      );
    }
  };

  const handleChange = (event, type) => {
    if (type === 'difficulty') setDifficulty(event.target.value);
    else if (type === 'controlCheck') setControlCheck(event.target.value);
  };

  const handleOperator = (event, type) => {
    if (type === 'difficulty') setDifficultyOperator(event.target.value);
    else if (type === 'controlCheck')
      setControlCheckOperator(event.target.value);
  };

  useEffect(() => {
    dispatch({
      type: 'CARD_INFORMATION_FILTER',
      data: {
        difficulty,
        difficultyOperator,
        controlCheck,
        controlCheckOperator,
      },
    });
  }, [difficulty, difficultyOperator, controlCheck, controlCheckOperator]);

  useEffect(() => {
    dispatch({
      type: 'CARD_TYPE_FILTER',
      data: {
        type: [...cardTypes],
      },
    });
  }, [cardTypes]);

  return (
    <div>
      <Text fontSize={'xl'}>Card Information</Text>
      <SimpleGrid mt={4} columns={[3, 5]} spacing={2}>
        {availableCardTypes.map((ct) => (
          <Button onClick={() => handleSelect(ct)} variant={isSelected(ct)}>
            <Text fontSize="sm">{ct}</Text>
          </Button>
        ))}
      </SimpleGrid>
      <SimpleGrid pt={4} columns={[2]} spacing={2}>
        <FormControl colorScheme={'accent'}>
          <FormLabel>Difficulty</FormLabel>
          <InputGroup>
            <InputLeftElement w={'70px'}>
              <Select
                value={difficultyOperator}
                onChange={(event) => handleOperator(event, 'difficulty')}
              >
                <option value="equals">=</option>
                <option value="greater-than">&gt;</option>
                <option value="greater-than-or-equal">&gt;=</option>
                <option value="less-than">&lt;</option>
                <option value="less-than-or-equal">&lt;=</option>
              </Select>
            </InputLeftElement>
            <Input
              focusBorderColor={
                colorMode === 'light' ? 'accent.500' : 'accent.200'
              }
              placeholder=" "
              value={difficulty}
              onChange={(event) => handleChange(event, 'difficulty')}
              pl={20}
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel>Control</FormLabel>
          <InputGroup>
            <InputLeftElement w={'70px'}>
              <Select
                value={controlCheckOperator}
                onChange={(event) => handleOperator(event, 'controlCheck')}
              >
                <option value="equals">=</option>
                <option value="greater-than">&gt;</option>
                <option value="greater-than-or-equal">&gt;=</option>
                <option value="less-than">&lt;</option>
                <option value="less-than-or-equal">&lt;=</option>
              </Select>
            </InputLeftElement>
            <Input
              focusBorderColor={
                colorMode === 'light' ? 'accent.500' : 'accent.200'
              }
              placeholder=" "
              value={controlCheck}
              onChange={(event) => handleChange(event, 'controlCheck')}
              pl={20}
            />
          </InputGroup>
        </FormControl>
      </SimpleGrid>
    </div>
  );
};

export default CardType;
