import React, { useContext } from 'react';
import { Box, Text, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Icon,
  Image,
  IconButton,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Show,
  useColorMode,
} from '@chakra-ui/react';
import { MdOutlineDarkMode, MdOutlineLightMode } from 'react-icons/md';
import { RxEnter, RxExit } from 'react-icons/rx';
import { AuthContext } from '../../contexts/AuthContext';
import { DesktopSearchbar, MobileSearchbar } from './components';
import { Authentication } from '../index';

const Header = () => {
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);
  const { toggleColorMode, colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      h="64px"
      display={'flex'}
      position={'fixed'}
      width={'100%'}
      flex="1"
      bg={'brand.900'}
      shadow={'md'}
      alignItems={'center'}
      justifyContent={'space-between'}
      pl={4}
      pr={4}
      zIndex={11}
    >
      <Box
        display={'flex'}
        alignItems={'baseline'}
        justifyContent={['center', 'center', 'flex-start']}
        ml={'10vw'}
        flex={[1, 1, 0]}
      >
        <Text
          fontWeight={'bold'}
          color={'accent.50'}
          fontSize={'1.4em'}
          stroke={'red'}
          _hover={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/cards');
          }}
        >
          UVSDECKS.
        </Text>
        <Text
          fontWeight={'bold'}
          fontSize={'1.4em'}
          _hover={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/cards');
          }}
          color={colorMode === 'dark' ? 'accent.200' : 'accent.400'}
        >
          GG
        </Text>
      </Box>
      <Show above={'sm'}>
        <Box flex={1} alignItems={'center'}>
          <DesktopSearchbar />
        </Box>
      </Show>
      <Box>
        <Show above={'sm'}>
          {Object.keys(currentUser).length <= 0 && (
            <Button
              aria-label="login button"
              leftIcon={<RxEnter />}
              _hover={{ color: 'brand.900', bg: 'accent.50' }}
              onClick={onOpen}
            >
              Login
            </Button>
          )}
          {Object.keys(currentUser).length > 0 && (
            <Menu>
              <MenuButton
                as={Button}
                leftIcon={
                  <Image
                    src={currentUser.photoURL || '/defaultUser.png'}
                    w={'30px'}
                    h={'30px'}
                    borderRadius={'full'}
                  />
                }
              >
                {currentUser.displayName}
              </MenuButton>
              <MenuList>
                {['admin'].includes(currentUser.role) && (
                  <MenuItem onClick={() => navigate('/admin')}>
                    Admin Tools
                  </MenuItem>
                )}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          )}
          <>{isOpen && <Authentication isOpen={isOpen} onClose={onClose} />}</>
        </Show>
        <Tooltip label={colorMode === 'dark' ? 'Light Mode' : 'Dark Mode'}>
          <IconButton
            colorScheme={'brand'}
            aria-label="toggle theme"
            onClick={toggleColorMode}
            icon={
              colorMode === 'dark' ? (
                <MdOutlineLightMode />
              ) : (
                <MdOutlineDarkMode />
              )
            }
            ml={4}
          />
        </Tooltip>
      </Box>
      <Show below={'md'}>
        <MobileSearchbar />
      </Show>
    </Box>
  );
};

export default Header;
