import React from 'react';
import { Text } from '@chakra-ui/react';

const Trait = ({ value }) => {
  return (
    <Text mr={2} fontWeight="extrabold" display="inline">
      {value}
    </Text>
  );
};

export default Trait;
