import React, { useState } from 'react';
import { Box, Icon, Text, useColorMode, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const NavItem = ({ active, icon, text, link }) => {
  const { colorMode } = useColorMode();
  const navigate = useNavigate();

  return (
    <Box
      display={'flex'}
      h="80px"
      w={['100%', '100%', '80px']}
      alignItems={'center'}
      justifyContent={'center'}
      _hover={{
        bg: colorMode === 'dark' ? 'brand.800' : 'brand.100',
        cursor: 'pointer',
      }}
      onClick={() => navigate(link)}
    >
      <VStack>
        <Icon
          as={icon}
          boxSize={['1.5em', '1.5em', '2em']}
          color={
            active ? 'accent.400' : colorMode === 'dark' ? 'white' : 'brand.900'
          }
        />
        <Text
          color={
            active ? 'accent.400' : colorMode === 'dark' ? 'white' : 'brand.900'
          }
          fontSize={['sm', 'sm', 'md']}
        >
          {text}
        </Text>
      </VStack>
    </Box>
  );
};

export default NavItem;
