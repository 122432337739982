import React, { useContext, useState } from 'react';
import {
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ModalFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from '../../../../contexts/AuthContext';

const Register = ({ setCurrentForm, onClose }) => {
  const { signup } = useContext(AuthContext);
  const toast = useToast();

  const validateDisplayName = (value) => {
    let error;
    if (!value) {
      error = 'Display Name is required';
    }
    return error;
  };

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error;
  };

  const validatePassword = (values) => {
    let error = '';
    const passwordRegex = /(?=.*[0-9])/;
    if (!values) {
      error = '*Required';
    } else if (values.length < 8) {
      error = '*Password must be 8 characters long.';
    } else if (!passwordRegex.test(values)) {
      error = '*Invalid password. Must contain one number.';
    }
    return error;
  };

  const validateConfirmPassword = (pass, value) => {
    let error = '';
    if (pass && value) {
      if (pass !== value) {
        error = 'Password not matched';
      }
    }
    return error;
  };

  return (
    <Formik
      initialValues={{
        displayName: '',
        email: '',
        password: '',
        confirmPassword: '',
      }}
      onSubmit={async (values, actions) => {
        try {
          actions.setStatus(false);
          await signup(values.email, values.password, values.displayName);
          toast({
            title: 'Successfully registered!',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
            containerStyle: {
              mt: '74px',
            },
          });
          onClose();
        } catch (error) {
          actions.setStatus('An error ocurred while registering.');
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {(props) => (
        <Form>
          {props.status && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              {props.status}
            </Alert>
          )}
          <Field name="displayName" validate={validateDisplayName}>
            {({ field, form }) => (
              <FormControl
                isInvalid={form.errors.displayName && form.touched.displayName}
              >
                <FormLabel>Display Name</FormLabel>
                <Input {...field} placeholder="Display Name" />
                <FormErrorMessage>{form.errors.displayName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="email" validate={validateEmail}>
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <FormLabel>Email Address</FormLabel>
                <Input {...field} placeholder="Email Address" />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="password" validate={validatePassword}>
            {({ field, form }) => (
              <FormControl
                mt={4}
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel>Password</FormLabel>
                <Input {...field} placeholder="Password" type={'password'} />
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="confirmPassword" validate={validateConfirmPassword}>
            {({ field, form }) => (
              <FormControl
                mt={4}
                isInvalid={
                  form.errors.confirmPassword && form.touched.confirmPassword
                }
              >
                <FormLabel>Confirm Password</FormLabel>
                <Input
                  {...field}
                  placeholder="Confirm Password"
                  type={'password'}
                />
                <FormErrorMessage>
                  {form.errors.confirmPassword}
                </FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setCurrentForm('login')}
            >
              Back
            </Button>
            <Button
              colorScheme="blue"
              type={'submit'}
              disabled={
                !props.isValid ||
                props.values.displayName === '' ||
                props.values.email === '' ||
                props.values.password === '' ||
                props.values.confirmPassword === ''
              }
            >
              Register
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default Register;
