import React, { useContext, useState } from 'react';
import {
  Alert,
  AlertIcon,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  ModalFooter,
  Button,
  useToast,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from '../../../../contexts/AuthContext';

const Login = ({ setCurrentForm, onClose }) => {
  const { login } = useContext(AuthContext);
  const toast = useToast();
  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = 'Email is required';
    }
    return error;
  };

  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = 'Password is required';
    }
    return error;
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={async (values, actions) => {
        try {
          actions.setStatus(false);
          await login(values.email, values.password);
          toast({
            title: 'Successfully logged in!',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'top-right',
            containerStyle: {
              mt: '74px',
            },
          });
          onClose();
        } catch (error) {
          actions.setStatus('Invalid email or password.');
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {(props) => (
        <Form>
          {props.status && (
            <Alert status="error" mb={4}>
              <AlertIcon />
              {props.status}
            </Alert>
          )}
          <Field name="email" validate={validateEmail}>
            {({ field, form }) => (
              <FormControl isInvalid={form.errors.email && form.touched.email}>
                <FormLabel>Email Address</FormLabel>
                <Input {...field} placeholder="Email Address" />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name="password" validate={validatePassword}>
            {({ field, form }) => (
              <FormControl
                mt={4}
                isInvalid={form.errors.password && form.touched.password}
              >
                <FormLabel>Password</FormLabel>
                <Input {...field} placeholder="Password" type={'password'} />
                <FormErrorMessage>{form.errors.password}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={() => setCurrentForm('register')}
            >
              Register
            </Button>
            <Button
              colorScheme="blue"
              type={'submit'}
              disabled={
                !props.isValid ||
                props.values.email === '' ||
                props.values.password === ''
              }
            >
              Login
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default Login;
