import React from 'react';
import { Box } from '@chakra-ui/react';
import { RxCopy, RxLayers, RxCube } from 'react-icons/rx';
import { NavItem } from './components';
import { useLocation } from 'react-router-dom';

const DesktopNavBar = () => {
  const location = useLocation();

  return (
    <Box
      position={'fixed'}
      w={'80px'}
      h={'100vh'}
      top={0}
      left={0}
      pt={'80px'}
      zIndex={10}
      shadow={'none'}
      bg={'chakra-body-bg'}
    >
      <NavItem
        active={location.pathname.includes('cards')}
        icon={RxCopy}
        text={'Cards'}
        link={'/cards'}
      />
      <NavItem
        active={location.pathname.includes('decks')}
        icon={RxLayers}
        text={'Decks'}
        link={'/decks'}
      />
      <NavItem
        active={location.pathname.includes('collection')}
        icon={RxCube}
        text={'Collection'}
        link={'/collection'}
      />
    </Box>
  );
};

export default DesktopNavBar;
