import React, { Fragment, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  Image,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { UvsCardModal } from './components';

const UvsCard = ({ card }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Card
      key={card.name}
      onClick={() => onOpen()}
      _hover={{ cursor: 'pointer' }}
      borderRadius={'xl'}
    >
      <Image src={card.image} alt={card.name} borderRadius={'2xl'} />
      {isOpen && <UvsCardModal isOpen={isOpen} onClose={onClose} card={card} />}
    </Card>
  );
};

export default UvsCard;
