import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Center,
  Divider,
  Hide,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import {
  Breaker,
  CardBlock,
  Combo,
  Echo,
  EX,
  Flash,
  Powerful,
  Stun,
  Terrain,
  Throw,
  Trait,
  Unique,
  Enhance,
  Form,
  Response,
} from '../../../CardInformation';
import {
  DeathSymbol,
  AirSymbol,
  AllSymbol,
  OrderSymbol,
  WaterSymbol,
  EarthSymbol,
  ChaosSymbol,
  GoodSymbol,
  FireSymbol,
  Set1Symbol,
  EvilSymbol,
  VoidSymbol,
  LifeSymbol,
} from '../../../CardInformation/Symbols';
import { High, Mid, Low } from '../../../CardInformation';
import { capitalize } from '../../../../utils/capitalize';

const UvsCardModal = ({ isOpen, onClose, card }) => {
  const testing = (node, index) => {};
  const { colorMode } = useColorMode();

  const replaceTokensWithComponents = (text) => {
    const components = {
      death: <DeathSymbol />,
      air: <AirSymbol />,
      all: <AllSymbol />,
      chaos: <ChaosSymbol />,
      earth: <EarthSymbol />,
      evil: <EvilSymbol />,
      fire: <FireSymbol />,
      good: <GoodSymbol />,
      life: <LifeSymbol />,
      order: <OrderSymbol />,
      void: <VoidSymbol />,
      water: <WaterSymbol />,
      attackhigh: <High />,
      attacklow: <Mid />,
      attackmid: <Low />,
    };

    let component = '';
    let componentText = text;

    console.log(text);

    return componentText.replace(/\{([^\}]+)\}/g, (match, token) => {
      if (token === 'attackhigh') return 'high';
      if (token === 'attackmid') return 'mid';
      if (token === 'attacklow') return 'low';
      return capitalize(token);
    });
  };

  const replaceAbilitiesWithComponents = (word) => {
    const pattern = /\b(Enhance|Form|Response)\b/;
    const match = word.match(pattern);

    if (match) {
      const keyword = match[0];
      const leadingText = word.slice(0, match.index).trim() + ' ' + keyword;
      const restOfText = word.slice(match.index + keyword.length).trim();

      if (keyword === 'Enhance') {
        return (
          <>
            <Enhance
              mr={restOfText.slice(0, 1) !== ':' ? 1 : 0}
              children={replaceTokensWithComponents(leadingText)}
            />
            <Text display={'inline'}>
              {replaceTokensWithComponents(restOfText)}
            </Text>
          </>
        );
      } else if (keyword === 'Response')
        return (
          <>
            <Response
              mr={restOfText.slice(0, 1) !== ':' ? 1 : 0}
              children={replaceTokensWithComponents(leadingText)}
            />
            <Text display={'inline'}>
              {replaceTokensWithComponents(restOfText)}
            </Text>
          </>
        );
      else if (keyword === 'Form')
        return (
          <>
            <Form
              mr={restOfText.slice(0, 1) !== ':' ? 1 : 0}
              children={replaceTokensWithComponents(leadingText)}
            />
            <Text display={'inline'}>
              {replaceTokensWithComponents(restOfText)}
            </Text>
          </>
        );
    }

    return <Text>{word}</Text>;
  };

  // Function to replace words with components
  function replaceWordsWithComponents(word, hasBullet) {
    if (word.includes('Stun'))
      return (
        <>
          <Stun value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Combo'))
      return (
        <>
          <Combo value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('EX'))
      return (
        <>
          <EX value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Powerful'))
      return (
        <>
          <Powerful value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Throw'))
      return (
        <>
          <Throw value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Breaker'))
      return (
        <>
          <Breaker value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Flash'))
      return (
        <>
          <Flash value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Terrain'))
      return (
        <>
          <Terrain value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Unique'))
      return (
        <>
          <Unique value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );
    if (word.includes('Echo'))
      return (
        <>
          <Echo value={word} />
          {hasBullet && (
            <Text display="inline" mr={2}>
              •
            </Text>
          )}
        </>
      );

    return (
      <>
        <Trait key={word} value={word} />
        {hasBullet && (
          <Text display="inline" mr={2}>
            •
          </Text>
        )}
      </>
    );
  }

  const sortedWords = (words) =>
    words.sort((a, b) => {
      const lowerA = a.toLowerCase();
      const lowerB = b.toLowerCase();

      if (lowerA.includes('combo') && !lowerB.includes('combo')) {
        return 1;
      }

      if (!lowerA.includes('combo') && lowerB.includes('combo')) {
        return -1;
      }

      return a.localeCompare(b);
    });

  return (
    <Modal
      isCentered={window.innerWidth > 1000}
      isOpen={isOpen}
      onClose={onClose}
      size={['full', 'full', 'full', '5xl']}
    >
      <ModalOverlay />
      <ModalContent minHeight={520} bg={'#212121'}>
        <Hide below={'lg'}>
          <ModalCloseButton
            color={'white'}
            position={['fixed', 'fixed', 'fixed', 'absolute']}
          />
        </Hide>
        <ModalBody m={0} p={0}>
          <Box display={{ md: 'flex' }}>
            <Box
              p={[0]}
              pt={[4, 0]}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Center>
                <Image
                  minWidth={['95vw', 375]}
                  maxWidth={['95vw', 375]}
                  src={card.image}
                  alt={card.name}
                  borderRadius={'2xl'}
                  align={'center'}
                />
              </Center>
            </Box>
            <Box p={8} mb={[50, 50, 50, 0]} width="100%">
              <CardBlock>
                <Center>
                  <Text color={'white'} fontWeight={'bold'} fontSize={'2xl'}>
                    {card.name}
                  </Text>
                </Center>
              </CardBlock>
              {card.keywords?.length > 0 && (
                <>
                  <Divider mb={4} mt={4} />
                  <CardBlock>
                    <Center>
                      <Text color={'white'} fontSize={'lg'}>
                        {sortedWords(card.keywords).map((word, index) => (
                          <>
                            {replaceWordsWithComponents(
                              word,
                              index < card.keywords.length - 1
                            )}
                          </>
                        ))}
                      </Text>
                    </Center>
                  </CardBlock>
                </>
              )}
              {card.abilities?.length > 0 && (
                <>
                  <Divider mb={4} mt={4} />
                  {card.abilities.map((ability) => (
                    <CardBlock>
                      <Text color={'white'} fontSize={'lg'}>
                        {replaceAbilitiesWithComponents(ability)}
                      </Text>
                    </CardBlock>
                  ))}
                </>
              )}
              <Divider mb={4} mt={4} />
            </Box>
          </Box>
        </ModalBody>

        <Hide above={'lg'}>
          <ModalFooter
            position={'fixed'}
            bottom={0}
            left={0}
            bg={colorMode === 'light' ? 'brand.200' : 'brand.800'}
            w={'100%'}
          >
            <Button onClick={() => onClose()}>Close</Button>
          </ModalFooter>
        </Hide>
      </ModalContent>
    </Modal>
  );
};

export default UvsCardModal;
