import React, { useContext, useEffect, useState } from 'react';
import {
  SimpleGrid,
  Text,
  Button,
  Flex,
  Box,
  Spacer,
  FormControl,
  FormLabel,
  Switch,
} from '@chakra-ui/react';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';

const availableKeywords = [
  'Ally',
  'Breaker',
  'Charge',
  'Combo',
  'EX',
  'Echo',
  'Flash',
  'Fury',
  'Kick',
  'Powerful',
  'Punch',
  'Ranged',
  'Slam',
  'Stun',
  'Taunt',
  'Tech',
  'Terrain',
  'Throw',
  'Unique',
  'Weapon',
];

const Keyword = () => {
  const { dispatch, cardFilter } = useContext(AuthContext);
  const [keywords, setKeywords] = useState([...cardFilter.keywords.items]);
  const [matchAll, setMatchAll] = useState(cardFilter.keywords.matchAll);

  const isSelected = (selectedKeyword) => {
    return keywords.includes(selectedKeyword) ? 'solid' : 'outline';
  };

  const handleToggle = () => {
    setMatchAll(!matchAll);
  };

  const handleSelect = (selectedKeyword) => {
    const cardTypeIndex = keywords.indexOf(selectedKeyword);

    if (cardTypeIndex > -1) {
      setKeywords([
        ...keywords.slice(0, cardTypeIndex),
        ...keywords.slice(cardTypeIndex + 1),
      ]);
    } else {
      setKeywords(
        [...keywords, selectedKeyword].sort((a, b) => a.localeCompare(b))
      );
    }
  };

  useEffect(() => {
    dispatch({
      type: 'CARD_KEYWORDS_FILTER',
      data: {
        keywords,
        matchAll,
      },
    });
  }, [keywords, matchAll]);

  return (
    <div>
      <Flex>
        <Text fontSize={'xl'}>Keywords</Text>
        <Spacer />
        <Box>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="email-alerts" mb="0">
              Match All
            </FormLabel>
            <Switch
              colorScheme="accent"
              isChecked={matchAll}
              onChange={() => handleToggle()}
            />
          </FormControl>
        </Box>
      </Flex>
      <SimpleGrid pt={4} columns={[3, 6]} spacing={2}>
        {availableKeywords.map((keyword) => (
          <Button
            variant={isSelected(keyword)}
            onClick={() => handleSelect(keyword)}
          >
            <Text fontSize="sm">{keyword}</Text>
          </Button>
        ))}
      </SimpleGrid>
    </div>
  );
};

export default Keyword;
