import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Cards from './pages/cards';
import Decks from './pages/decks';
import Collection from './pages/collection';
import Admin from './pages/admin';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/cards" element={<Cards />} />
      <Route path="/decks" element={<Decks />} />
      <Route path="/collection" element={<Collection />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="*" element={<Navigate to={'/cards'} replace />} />
    </Routes>
  );
};

export default AppRoutes;
