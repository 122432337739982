import React, { useContext, useEffect, useState } from 'react';
import {
  SimpleGrid,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  useColorMode,
  InputLeftElement,
  Select,
  InputGroup,
} from '@chakra-ui/react';
import { AuthContext } from '../../../../../../../../contexts/AuthContext';

const CardInformation = () => {
  const { dispatch, cardFilter, cardFilterStaging } = useContext(AuthContext);
  const colorMode = useColorMode();
  const [health, setHealth] = useState(cardFilter.health);
  const [healthOperator, setHealthOperator] = useState(
    cardFilter.healthOperator
  );
  const [handSize, setHandSize] = useState(cardFilter.handSize);
  const [handSizeOperator, setHandSizeOperator] = useState(
    cardFilter.handSizeOperator
  );

  const handleChange = (event, item) => {
    if (item === 'health') setHealth(event.target.value);
    else if (item === 'handSize') setHandSize(event.target.value);
  };

  const handleOperator = (event, type) => {
    if (type === 'health') setHealthOperator(event.target.value);
    else if (type === 'handSize') setHandSizeOperator(event.target.value);
  };

  useEffect(() => {
    dispatch({
      type: 'CARD_INFORMATION_FILTER',
      data: {
        handSize,
        handSizeOperator,
        health,
        healthOperator,
      },
    });
  }, [handSize, handSizeOperator, health, healthOperator]);

  return (
    <div>
      <Text fontSize={'xl'}>Character</Text>
      <SimpleGrid columns={[2]} spacing={2}>
        <FormControl mt={1}>
          <FormLabel>Health</FormLabel>
          <InputGroup>
            <InputLeftElement w={'70px'}>
              <Select
                value={healthOperator}
                onChange={(event) => handleOperator(event, 'health')}
              >
                <option value="equals">=</option>
                <option value="greater-than">&gt;</option>
                <option value="greater-than-or-equal">&gt;=</option>
                <option value="less-than">&lt;</option>
                <option value="less-than-or-equal">&lt;=</option>
              </Select>
            </InputLeftElement>
            <Input
              focusBorderColor={
                colorMode === 'light' ? 'accent.500' : 'accent.200'
              }
              placeholder=" "
              value={health}
              onChange={(event) => handleChange(event, 'health')}
              pl={20}
            />
          </InputGroup>
        </FormControl>
        <FormControl mt={1}>
          <FormLabel>Hand Size</FormLabel>
          <InputGroup>
            <InputLeftElement w={'70px'}>
              <Select
                value={handSizeOperator}
                onChange={(event) => handleOperator(event, 'handSize')}
              >
                <option value="equals">=</option>
                <option value="greater-than">&gt;</option>
                <option value="greater-than-or-equal">&gt;=</option>
                <option value="less-than">&lt;</option>
                <option value="less-than-or-equal">&lt;=</option>
              </Select>
            </InputLeftElement>
            <Input
              focusBorderColor={
                colorMode === 'light' ? 'accent.500' : 'accent.200'
              }
              value={handSize}
              placeholder=" "
              onChange={(event) => handleChange(event, 'handSize')}
              pl={20}
            />
          </InputGroup>
        </FormControl>
      </SimpleGrid>
    </div>
  );
};

export default CardInformation;
