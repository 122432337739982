import {
  CARD_FILTER,
  CARD_FILTER_RESET,
  STATE_SAVE,
  TOAST,
  CARD_COUNT,
  CARD_FORMAT_FILTER,
  CARD_KEYWORDS_FILTER,
  CARD_SYMBOLS_FILTER,
  CARD_TYPE_FILTER,
  CARD_SEARCH,
  CARD_INFORMATION_FILTER,
} from '../actions';
import initState from '../contexts/initState';

const save_user_state = (state, { node, data }) => {
  return node !== ''
    ? {
        ...state,
        [node]: data,
      }
    : {
        ...state,
        ...data,
      };
};

const toast = (state, { data }) => {
  return {
    ...state,
    toast: [...data],
  };
};

const card_search = (state, { data }) => {
  return {
    ...state,
    cardFilter: {
      ...state.cardFilter,
      ...data,
    },
  };
};

const card_filter_reset = (state, { data }) => {
  return {
    ...state,
    cardFilterStaging: {
      ...initState.cardFilterStaging,
    },
    cardFilter: {
      ...state.cardFilter,
      ...initState.cardFilterStaging,
    },
  };
};

const card_filter = (state, { data }) => {
  return {
    ...state,
    cardFilter: {
      ...state.cardFilter,
      ...state.cardFilterStaging,
    },
  };
};

const card_type_filter = (state, { data: { type } }) => {
  return {
    ...state,
    cardFilterStaging: {
      ...state.cardFilterStaging,
      type: [...type],
    },
  };
};

const card_format_filter = (state, { data: { format } }) => {
  return {
    ...state,
    cardFilterStaging: {
      ...state.cardFilterStaging,
      format,
    },
  };
};

const card_symbols_filter = (state, { data: { symbols, matchAll } }) => {
  return {
    ...state,
    cardFilterStaging: {
      ...state.cardFilterStaging,
      symbols: {
        items: [...symbols],
        matchAll,
      },
    },
  };
};

const card_keywords_filter = (state, { data: { keywords, matchAll } }) => {
  return {
    ...state,
    cardFilterStaging: {
      ...state.cardFilterStaging,
      keywords: {
        items: [...keywords],
        matchAll,
      },
    },
  };
};

const card_information_filter = (state, { data }) => {
  return {
    ...state,
    cardFilterStaging: {
      ...state.cardFilterStaging,
      ...data,
    },
  };
};

const card_count = (state, { data }) => {
  console.log(data);
  return {
    ...state,
    cardCount: data.cardCount || 0,
  };
};

export default (state, act) => {
  switch (act.type) {
    case STATE_SAVE:
      return save_user_state(state, act);
    case TOAST:
      return toast(state, act);
    case CARD_SEARCH:
      return card_search(state, act);
    case CARD_FILTER:
      return card_filter(state, act);
    case CARD_FILTER_RESET:
      return card_filter_reset(state, act);
    case CARD_TYPE_FILTER:
      return card_type_filter(state, act);
    case CARD_FORMAT_FILTER:
      return card_format_filter(state, act);
    case CARD_SYMBOLS_FILTER:
      return card_symbols_filter(state, act);
    case CARD_KEYWORDS_FILTER:
      return card_keywords_filter(state, act);
    case CARD_INFORMATION_FILTER:
      return card_information_filter(state, act);
    case CARD_COUNT:
      return card_count(state, act);
    default:
      return state;
  }
};
