/*
    To create an action name use the following template, this will help ensure consistency throughout the app:

    "ACTION_SUBJECT_MODIFIER"
        ACTION | needs to be either CREATE, LIST, READ, REMOVE or SAVE
        SUBJECT | refers to what object you are working with (ex. EVENT, WORKFLOW, ACTION, etc...)
        MODIFIER | refers to what you are doing with the SUBJECT if the SUBJECT does not cover your request.
                   ex. ACTION_CREATE works for creating an action and ACTION_CREATE_DUPLICATE works for creating a duplicate action
 */

export const STATE_SAVE = 'STATE_SAVE';
export const TOAST = 'TOAST';
export const CARD_FILTER = 'CARD_FILTER';
export const CARD_FILTER_RESET = 'CARD_FILTER_RESET';
export const CARD_SEARCH = 'CARD_SEARCH';
export const USER_PROFILE_LOAD_PROFILE = 'USER_PROFILE_LOAD_PROFILE';
export const CARD_COUNT = 'CARD_COUNT';

export const CARD_TYPE_FILTER = 'CARD_TYPE_FILTER';
export const CARD_FORMAT_FILTER = 'CARD_FORMAT_FILTER';
export const CARD_SYMBOLS_FILTER = 'CARD_SYMBOLS_FILTER';
export const CARD_KEYWORDS_FILTER = 'CARD_KEYWORDS_FILTER';
export const CARD_INFORMATION_FILTER = 'CARD_INFORMATION_FILTER';
