import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Enhance = ({ children, mr = 2 }) => {
  return (
    <Text
      mr={mr}
      bgColor="#fbea25"
      bgClip="text"
      fontWeight="extrabold"
      display="inline"
    >
      {children}
    </Text>
  );
};

export default Enhance;
