import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { Login, Register } from './components';

const Authentication = ({ isOpen, onClose }) => {
  const [currentForm, setCurrentForm] = useState('login');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={['full', 'full', 'md']}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {currentForm === 'login' ? 'Login' : 'Register'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {currentForm === 'login' && (
            <Login setCurrentForm={setCurrentForm} onClose={onClose} />
          )}
          {currentForm === 'register' && (
            <Register setCurrentForm={setCurrentForm} onClose={onClose} />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Authentication;
